// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-asesoria-js": () => import("./../src/pages/asesoria.js" /* webpackChunkName: "component---src-pages-asesoria-js" */),
  "component---src-pages-faq-js": () => import("./../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-guia-docente-js": () => import("./../src/pages/guia-docente.js" /* webpackChunkName: "component---src-pages-guia-docente-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-politica-privacidad-js": () => import("./../src/pages/politica-privacidad.js" /* webpackChunkName: "component---src-pages-politica-privacidad-js" */),
  "component---src-pages-repositorio-cursos-js": () => import("./../src/pages/repositorio-cursos.js" /* webpackChunkName: "component---src-pages-repositorio-cursos-js" */),
  "component---src-pages-repositorio-imagenes-js": () => import("./../src/pages/repositorio-imagenes.js" /* webpackChunkName: "component---src-pages-repositorio-imagenes-js" */),
  "component---src-pages-solicitud-js": () => import("./../src/pages/solicitud.js" /* webpackChunkName: "component---src-pages-solicitud-js" */),
  "component---src-pages-tutorialesobsoletos-js": () => import("./../src/pages/tutorialesobsoletos.js" /* webpackChunkName: "component---src-pages-tutorialesobsoletos-js" */),
  "component---src-templates-faq-template-js": () => import("./../src/templates/faqTemplate.js" /* webpackChunkName: "component---src-templates-faq-template-js" */)
}

